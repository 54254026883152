import React from "react";
import Chief from "../cc/chief";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { isBrowser } from "../util/helper";

const ChiefPage = () => {
	return (
		<Layout>
			<SEO title="Kampányfőnök" />
			<div className="container px-4">
				<h1 className="pt-12 text-2xl leading-tight font-semibold">Kampányfőnök</h1>
				<div>{isBrowser() ? <Chief /> : ""}</div>
			</div>
		</Layout>
	);
};

export default ChiefPage;
