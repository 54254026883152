import "./chief.scss";
import "../ui/dc-components";
import { html } from "lit-html";
import { HauntedFunc, useEffect, useMemo, useState } from "../util/CustomHauntedHooks";
import { component } from "haunted";
import * as DC from "../ui/dc-components-typing";
import * as _ from "lodash";
import React from "react";
import useCustomElement from "../util/useCustomElement";
import { isBrowser } from "../util/helper";
import { formatNumber, toSafeNumber } from "../util/numberHelper";
import {
	calculateMagnetResult as calculateMagnetResult,
	ElectionType,
	getOevkKey,
	MagnetResult,
	NumDict,
	OsszefogasMagnetSource,
	OsszefogasOevkDetail,
} from "../util/magnetHelper";
import {
	DistributionType,
	DISTRIBUTION_DATA_SOURCE,
	getModositottSource,
	MAGNETS,
	safeNumber,
	OevkOrderType,
	OevkOrderTypeDataSource,
	sumNumDict,
	calculateVotesByMandate,
	getAllSeats,
	roundPercentage,
	SideType,
	MAGNET_LABELS,
} from "./chief_helper";
import { useMandateSlider } from "./svg/mandateSlider";
import IMask from "imask";
import { sluggify } from "../util/sluggifier";

const observedAttributes: (keyof Properties)[] = [];
const useShadowDOM = false;
const name = "th-chief";

interface Properties {}

const DEFAULTS = {
	election: "Egyenletes" as ElectionType,
	osszefogasPlusVotes: 0,
	fideszPlusVotes: 0,
	distribution: "Billegő" as DistributionType,
	boosterLetter: true,
	boosterNationality: true,
	showBase: false,
	oevkOrder: "Név" as OevkOrderType,
};

const Component: HauntedFunc<Properties> = () => {
	// HELPER

	const resetModositok = () => {
		setCurrentElection(DEFAULTS.election);
		setOsszefogasPlusVotes(DEFAULTS.osszefogasPlusVotes);
		setFideszPlusVotes(DEFAULTS.fideszPlusVotes);
		setCurrentDistribution(DEFAULTS.distribution);
		setBoosterLetter(DEFAULTS.boosterLetter);
		setBoosterNationality(DEFAULTS.boosterNationality);
		setShowBase(DEFAULTS.showBase);
		setCurrentOevkOrder(DEFAULTS.oevkOrder);
	};

	const getWinnerLoserColor = (mandatum1: number, mandatum2: number, type: SideType) => {
		const winnerColor = type === "OSSZEFOGAS" ? "text-brand-blue" : "text-brand-orange";
		return mandatum1 > mandatum2 ? winnerColor : "";
	};

	const getWinnerBg = (osszefogasMandate: number, fideszMandate) => {
		return osszefogasMandate > fideszMandate ? "bg-osszefogas" : "bg-fidesz";
	};

	const getSwappedBaseBg = (
		modositottOsszefogasMandate: number,
		alapOsszefogasMandate: number,
		modositottFideszMandate: number,
		alapFideszMandate: number
	) => {
		if (modositottOsszefogasMandate > modositottFideszMandate && alapOsszefogasMandate < alapFideszMandate) {
			return "bg-fidesz";
		}

		if (modositottOsszefogasMandate < modositottFideszMandate && alapOsszefogasMandate > alapFideszMandate) {
			return "bg-osszefogas";
		}

		return "";
	};

	const setMandates = (type: SideType, ms: number) => {
		const result = calculateVotesByMandate(
			currentElection,
			currentDistribution,
			boosterLetter,
			boosterNationality,
			type,
			ms
		);
		setOsszefogasPlusVotes((type === "OSSZEFOGAS" ? 1 : -1) * result);
		setFideszPlusVotes((type === "OSSZEFOGAS" ? -1 : 1) * result);
	};

	// COMPONENT

	const [currentElection, setCurrentElection] = useState<ElectionType>(DEFAULTS.election);
	const currentSource = useMemo<OsszefogasMagnetSource>(() => MAGNETS[currentElection], [currentElection]);
	const [osszefogasPlusVotes, setOsszefogasPlusVotes] = useState<number>(DEFAULTS.osszefogasPlusVotes);
	const [fideszPlusVotes, setFideszPlusVotes] = useState<number>(DEFAULTS.fideszPlusVotes);
	const [currentDistribution, setCurrentDistribution] = useState<DistributionType>(DEFAULTS.distribution);
	const [boosterLetter, setBoosterLetter] = useState<boolean>(DEFAULTS.boosterLetter);
	const [boosterNationality, setBoosterNationality] = useState<boolean>(DEFAULTS.boosterNationality);
	const [showBase, setShowBase] = useState<boolean>(DEFAULTS.showBase);
	const [currentOevkOrder, setCurrentOevkOrder] = useState<OevkOrderType>(DEFAULTS.oevkOrder);
	const calculated = useMemo<{
		currentMagnet: MagnetResult;
		modositottSource: OsszefogasMagnetSource;
		modositottMagnet: MagnetResult;
	}>(() => {
		const currentMagnet = calculateMagnetResult(currentSource);
		const modositottSource = getModositottSource(
			currentSource,
			osszefogasPlusVotes,
			fideszPlusVotes,
			currentDistribution,
			boosterLetter,
			boosterNationality
		);
		const modositottMagnet = calculateMagnetResult(modositottSource);
		return { currentMagnet, modositottSource, modositottMagnet };
	}, [currentSource, osszefogasPlusVotes, fideszPlusVotes, currentDistribution, boosterLetter, boosterNationality]);

	const mandateSlider = useMandateSlider({
		fideszMandate:
			(calculated.modositottMagnet.seats.all.FIDESZ ?? 0) +
			(calculated.modositottMagnet.seats.nationality.FIDESZ ?? 0),
	});

	useEffect(() => {
		if (mandateSlider.dispatchMandate) {
			setMandates("FIDESZ", mandateSlider.fideszMandate);
		}
	}, [mandateSlider.dispatchMandate]);

	useEffect(() => {
		// setOsszefogasPlusVotes(30000);
		// setShowBase(true);
		window.setTimeout(() => {
			IMask(document.getElementById("szavazat-osszefogas-mask")?.getElementsByTagName("input")[0] as any, {
				mask: Number,
				min: -10000000,
				max: 10000000,
				thousandsSeparator: " ",
			});

			IMask(document.getElementById("szavazat-fidesz-mask")?.getElementsByTagName("input")[0] as any, {
				mask: Number,
				min: -100000000,
				max: 100000000,
				thousandsSeparator: " ",
			});
		}, 0);
	}, []);

	// TEMPLATE

	const htmlNumber = (num: number) => {
		return html`${formatNumber(num)}`;
	};

	const htmlTableRowVotesPlusMandate = (
		alapVotes: NumDict,
		alapSeats: NumDict,
		modositottVotes: NumDict,
		modositottSeats: NumDict,
		withPercentage: boolean
	) => {
		const alapOsszefogasVotes = safeNumber(alapVotes, "OSSZEFOGAS");
		const alapFideszVotes = safeNumber(alapVotes, "FIDESZ");
		const alapOsszefogasSeats = safeNumber(alapSeats, "OSSZEFOGAS");
		const alapFideszSeats = safeNumber(alapSeats, "FIDESZ");
		const alapSeatsSum = alapOsszefogasSeats + alapFideszSeats === 0 ? 1 : alapOsszefogasSeats + alapFideszSeats;
		const modositottOsszefogasVotes = safeNumber(modositottVotes, "OSSZEFOGAS");
		const modositottFideszVotes = safeNumber(modositottVotes, "FIDESZ");
		const modositottOsszefogasSeats = safeNumber(modositottSeats, "OSSZEFOGAS");
		const modositottFideszSeats = safeNumber(modositottSeats, "FIDESZ");
		const modositottSeatsSum =
			modositottOsszefogasSeats + modositottFideszSeats === 0
				? 1
				: modositottOsszefogasSeats + modositottFideszSeats;

		return html`<td>
				<div class="${getWinnerLoserColor(modositottOsszefogasSeats, modositottFideszSeats, "OSSZEFOGAS")}">
					${modositottOsszefogasSeats}
					${withPercentage ? html`(${roundPercentage(modositottOsszefogasSeats / modositottSeatsSum)}%)` : ""}
				</div>
				<div>${htmlNumber(modositottOsszefogasVotes)}</div>
				${showBase
					? html`<div class="base-color">
							<div>
								[${alapOsszefogasSeats}${withPercentage
									? html` (${roundPercentage(alapOsszefogasSeats / alapSeatsSum)}%)`
									: ""}]
							</div>
							<div>${htmlAlap(alapOsszefogasVotes, modositottOsszefogasVotes)}</div>
					  </div>`
					: ""}
			</td>
			<td>
				<div class="${getWinnerLoserColor(modositottFideszSeats, modositottOsszefogasSeats, "FIDESZ")}">
					${modositottFideszSeats}
					${withPercentage ? html`(${roundPercentage(modositottFideszSeats / modositottSeatsSum)}%)` : ""}
				</div>
				<div>${htmlNumber(modositottFideszVotes)}</div>
				${showBase
					? html`<div class="base-color">
							<div>
								[${alapFideszSeats}${withPercentage
									? html` (${roundPercentage(alapFideszSeats / alapSeatsSum)}%)`
									: ""}]
							</div>
							<div>${htmlAlap(alapFideszVotes, modositottFideszVotes)}</div>
					  </div>`
					: ""}
			</td>
			<td>
				<div>${modositottOsszefogasSeats + modositottFideszSeats}</div>
				<div>${htmlNumber(modositottOsszefogasVotes + modositottFideszVotes)}</div>
				${showBase
					? html`<div class="base-color">
							<div>[${alapOsszefogasSeats + alapFideszSeats}]</div>
							<div>[${htmlNumber(alapOsszefogasVotes + alapFideszVotes)}]</div>
					  </div>`
					: ""}
			</td>`;
	};

	const htmlTableRowMandates = (alapSeats: NumDict, modositottSeats: NumDict) => {
		const alapOsszefogasSeats = safeNumber(alapSeats, "OSSZEFOGAS");
		const alapFideszSeats = safeNumber(alapSeats, "FIDESZ");
		const alapSeatsSum = alapOsszefogasSeats + alapFideszSeats === 0 ? 1 : alapOsszefogasSeats + alapFideszSeats;
		const modositottOsszefogasSeats = safeNumber(modositottSeats, "OSSZEFOGAS");
		const modositottFideszSeats = safeNumber(modositottSeats, "FIDESZ");
		const modositottSeatsSum =
			modositottOsszefogasSeats + modositottFideszSeats === 0
				? 1
				: modositottOsszefogasSeats + modositottFideszSeats;
		return html`<td>
				<div class="${getWinnerLoserColor(modositottOsszefogasSeats, modositottFideszSeats, "OSSZEFOGAS")}">
					${modositottOsszefogasSeats} (${roundPercentage(modositottOsszefogasSeats / modositottSeatsSum)}%)
				</div>
				${showBase
					? html`<div class="base-color">
							<div>
								[${alapOsszefogasSeats} (${roundPercentage(alapOsszefogasSeats / alapSeatsSum)}%)]
							</div>
					  </div>`
					: ""}
			</td>
			<td>
				<div class="${getWinnerLoserColor(modositottFideszSeats, modositottOsszefogasSeats, "FIDESZ")}">
					${modositottFideszSeats} (${roundPercentage(modositottFideszSeats / modositottSeatsSum)}%)
				</div>
				${showBase
					? html`<div class="base-color">
							<div>[${alapFideszSeats} (${roundPercentage(alapFideszSeats / alapSeatsSum)}%)]</div>
					  </div>`
					: ""}
			</td>
			<td>
				<div>${modositottOsszefogasSeats + modositottFideszSeats}</div>
				${showBase
					? html`<div class="base-color">
							<div>[${alapOsszefogasSeats + alapFideszSeats}]</div>
					  </div>`
					: ""}
			</td>`;
	};

	const htmlTableRowVotesOnly = (alapVotes: NumDict, modositottVotes: NumDict) => {
		const alapOsszefogasVotes = safeNumber(alapVotes, "OSSZEFOGAS");
		const alapFideszVotes = safeNumber(alapVotes, "FIDESZ");
		const modositottOsszefogasVotes = safeNumber(modositottVotes, "OSSZEFOGAS");
		const modositottFideszVotes = safeNumber(modositottVotes, "FIDESZ");
		return html`<td>
				<div>${htmlNumber(modositottOsszefogasVotes)}</div>
				${showBase
					? html`<div class="base-color">
							<div>${htmlAlap(alapOsszefogasVotes, modositottOsszefogasVotes)}</div>
					  </div>`
					: ""}
			</td>
			<td>
				<div>${htmlNumber(modositottFideszVotes)}</div>
				${showBase
					? html`<div class="base-color">
							<div>${htmlAlap(alapFideszVotes, modositottFideszVotes)}</div>
					  </div>`
					: ""}
			</td>
			<td>
				<div>${htmlNumber(modositottOsszefogasVotes + modositottFideszVotes)}</div>
				${showBase
					? html`<div class="base-color">
							<div>
								${htmlAlap(
									alapOsszefogasVotes + alapFideszVotes,
									modositottOsszefogasVotes + modositottFideszVotes
								)}
							</div>
					  </div>`
					: ""}
			</td>`;
	};

	const htmlModositok = () => {
		return html`
        <div>
            <table id="tableModositok" class="w-full mt-4">
                <tbody>
                    <tr>
                        <td>Kiindulás</td>
                        <td colspan="4">
                            <dc-select
                                .dataSource=${MAGNET_LABELS}
                                .selectedValues=${currentElection}
                                @change=${(e: DC.Select.SelectChangeEvent) => {
									setCurrentElection(e.detail.selectedValue as ElectionType);
								}}
                            ></dc-select>
                            <dc-checkbox
                                class="mt-2"
                                .label=${"Mutasd a táblázatban is"}
                                .checked=${showBase}
                                @change=${(e: DC.Checkbox.ChangeEvent) => {
									setShowBase(e.detail.checked);
								}}
                            ></dc-select>
                        </td>
                    </tr>
                    <tr>
                        <td>Fidesz booster</td>
                        <td colspan="4">
                            <div class="space-y-4 md:flex md:space-y-0 md:space-x-4">
                                <dc-checkbox
                                    .label=${"Levél"}
                                    .checked=${boosterLetter}
                                    @change=${(e: DC.Checkbox.ChangeEvent) => {
										setBoosterLetter(e.detail.checked);
									}}
                                ></dc-checkbox>
                                <dc-checkbox
                                    .label=${"Nemzetiségi"}
                                    .checked=${boosterNationality}
                                    @change=${(e: DC.Checkbox.ChangeEvent) => {
										setBoosterNationality(e.detail.checked);
									}}
                                ></dc-checkbox>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <div>Szavazatok (+/-)</div>
                                <button class="btn btn-primary btn-green mt-2" @click=${resetModositok}>Alaphelyzet</button>
                            </div>
                        </td>
                        <td colspan="4">
                            <div class="md:flex md:space-x-4">
                                <div class="md:w-1/2">
                                    <dc-input
                                        id="szavazat-osszefogas-mask"
                                        .label=${"Összefogás"}
                                        .value=${formatNumber(osszefogasPlusVotes)}
                                        .selectOnFocus=${true}
                                        @change=${(e: DC.Input.ChangeEvent) => {
											setOsszefogasPlusVotes(toSafeNumber(e.detail.value) ?? 0);
										}}
                                    ></dc-input>
                                </div>
                                <div class="md:w-1/2">
                                    <dc-input
                                        id="szavazat-fidesz-mask"
                                        .label=${"Fidesz"}
                                        .value=${formatNumber(fideszPlusVotes)}
                                        .selectOnFocus=${true}
                                        @change=${(e: DC.Input.ChangeEvent) => {
											setFideszPlusVotes(toSafeNumber(e.detail.value) ?? 0);
										}}
                                    ></dc-input>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Szavazatok szétosztása</td>
                        <td colspan="4">
                            <dc-select
                                .dataSource=${DISTRIBUTION_DATA_SOURCE}
                                .selectedValues=${currentDistribution}
                                @change=${(e: DC.Select.SelectChangeEvent) => {
									setCurrentDistribution(e.detail.selectedValue as DistributionType);
								}}
                            ></dc-select>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>`;
	};

	const htmlSzavazatok = (alapMagnetResult: MagnetResult, modositottMagnetResult: MagnetResult) => {
		return html`<div>
			<div class="my-4 font-bold">Mandátumok/Szavazatok</div>
			<table class="w-full table-fixed">
				<thead>
					<th></th>
					<th>Összefogás</th>
					<th>Fidesz</th>
					<th><div>Σ</div></th>
				</thead>
				<tbody>
					<tr class="font-bold">
						<td>Σ</td>
						${htmlTableRowMandates(
							sumNumDict(alapMagnetResult.seats.all, alapMagnetResult.seats.nationality),
							sumNumDict(modositottMagnetResult.seats.all, modositottMagnetResult.seats.nationality)
						)}
					</tr>
					<tr class="font-bold">
						<td>Egyéni</td>
						${htmlTableRowVotesPlusMandate(
							alapMagnetResult.ind.votes,
							alapMagnetResult.seats.ind,
							modositottMagnetResult.ind.votes,
							modositottMagnetResult.seats.ind,
							true
						)}
					</tr>
					${boosterNationality
						? html`<tr class="font-bold">
								<td>Nemzetiségi</td>
								${htmlTableRowVotesPlusMandate(
									alapMagnetResult.list.nationality,
									alapMagnetResult.seats.nationality,
									modositottMagnetResult.list.nationality,
									modositottMagnetResult.seats.nationality,
									false
								)}
						  </tr>`
						: ""}
					<tr class="font-bold">
						<td>Σ Listás</td>
						${htmlTableRowVotesPlusMandate(
							sumNumDict(alapMagnetResult.ind.sumListFraction, alapMagnetResult.list.sum),
							alapMagnetResult.seats.list,
							sumNumDict(modositottMagnetResult.ind.sumListFraction, modositottMagnetResult.list.sum),
							modositottMagnetResult.seats.list,
							false
						)}
					</tr>
					<tr>
						<td>Győztes kompenzáció</td>
						${htmlTableRowVotesOnly(
							alapMagnetResult.ind.listWinnerFraction,
							modositottMagnetResult.ind.listWinnerFraction
						)}
					</tr>
					<tr>
						<td>Vesztes kompenzáció</td>
						${htmlTableRowVotesOnly(
							alapMagnetResult.ind.listLoserFraction,
							modositottMagnetResult.ind.listLoserFraction
						)}
					</tr>
					<tr>
						<td>Pártlistás</td>
						${htmlTableRowVotesOnly(alapMagnetResult.list.party, modositottMagnetResult.list.party)}
					</tr>
					${boosterLetter
						? html`<tr>
								<td>Levél</td>
								${htmlTableRowVotesOnly(
									alapMagnetResult.list.letter,
									modositottMagnetResult.list.letter
								)}
						  </tr>`
						: ""}
				</tbody>
			</table>
		</div>`;
	};

	const htmlAlap = (alap: number, modositott: number) => {
		if (alap - modositott !== 0) {
			return html`[${htmlNumber(alap)} ${modositott > alap ? "+" : "-"}
			${htmlNumber(Math.abs(modositott - alap))}]`;
		}
		return html`[${htmlNumber(alap)}]`;
	};

	const htmlOevks = (alapSource: OsszefogasMagnetSource, modositottSource: OsszefogasMagnetSource) => {
		return html`<div>
			<div class="my-4 flex justify-between">
				<div class="font-bold">OEVK</div>
				<div>
					<dc-select
						.label=${"Rendezés"}
						.dataSource=${OevkOrderTypeDataSource}
						.selectedValues=${currentOevkOrder}
						@change=${(e: DC.Select.SelectChangeEvent) => {
							setCurrentOevkOrder(e.detail.selectedValue as OevkOrderType);
						}}
					></dc-select>
				</div>
			</div>
			<table class="w-full table-fixed">
				<thead>
					<th>Név</th>
					<th>Összefogás</th>
					<th>Fidesz</th>
					<th><div>Különbség</div></th>
				</thead>
				<tbody>
					${_.sortBy(Object.values(modositottSource.oevks), (oevk) => {
						if (currentOevkOrder === "Név") {
							return `${oevk.county}-${oevk.oevk}`;
						} else if (currentOevkOrder === "Összefogás") {
							return oevk.ind.FIDESZ - oevk.ind.OSSZEFOGAS;
						} else if (currentOevkOrder === "Fidesz") {
							return oevk.ind.OSSZEFOGAS - oevk.ind.FIDESZ;
						} else {
							return `${oevk.county}-${oevk.oevk}`;
						}
					}).map((mOevk) => {
						const aOevk: OsszefogasOevkDetail = alapSource.oevks[getOevkKey(mOevk.county, mOevk.oevk, "@")];
						return html`<tr class="${getWinnerBg(mOevk.ind.OSSZEFOGAS, mOevk.ind.FIDESZ)}">
							<td>
								<a
									class="link text-brand-green"
									href=${`/oevks?name=${sluggify(`${mOevk.county}-${mOevk.oevk}`)}`}
									>${mOevk.county}-${mOevk.oevk}</a
								>
							</td>
							<td class="${getWinnerLoserColor(mOevk.ind.OSSZEFOGAS, mOevk.ind.FIDESZ, "OSSZEFOGAS")}">
								<div>${htmlNumber(mOevk.ind.OSSZEFOGAS)}</div>
								${showBase
									? html`<div
											class="base-color ${getSwappedBaseBg(
												mOevk.ind.OSSZEFOGAS,
												aOevk.ind.OSSZEFOGAS,
												mOevk.ind.FIDESZ,
												aOevk.ind.FIDESZ
											)}"
									  >
											<div>${htmlAlap(aOevk.ind.OSSZEFOGAS, mOevk.ind.OSSZEFOGAS)}</div>
									  </div>`
									: ""}
							</td>
							<td class="${getWinnerLoserColor(mOevk.ind.FIDESZ, mOevk.ind.OSSZEFOGAS, "FIDESZ")}">
								<div>${htmlNumber(mOevk.ind.FIDESZ)}</div>
								${showBase
									? html`<div
											class="base-color ${getSwappedBaseBg(
												mOevk.ind.OSSZEFOGAS,
												aOevk.ind.OSSZEFOGAS,
												mOevk.ind.FIDESZ,
												aOevk.ind.FIDESZ
											)}"
									  >
											<div>${htmlAlap(aOevk.ind.FIDESZ, mOevk.ind.FIDESZ)}</div>
									  </div>`
									: ""}
							</td>
							<td>
								<div>${htmlNumber(mOevk.ind.OSSZEFOGAS - mOevk.ind.FIDESZ)}</div>
								${showBase
									? html`<div class="base-color">
											<div>[${htmlNumber(aOevk.ind.OSSZEFOGAS - aOevk.ind.FIDESZ)}]</div>
									  </div>`
									: ""}
							</td>
						</tr>`;
					})}
				</tbody>
			</table>
		</div>`;
	};

	const htmlMandateSlider = () => {
		return html`<div>
			${mandateSlider.template()}
			<div class="flex justify-between">
				<div class="space-y-1">
					<button
						class="btn btn-primary btn-small btn-blue"
						@click=${() => {
							setMandates(
								"OSSZEFOGAS",
								getAllSeats(
									calculated.modositottMagnet.seats.all,
									calculated.modositottMagnet.seats.nationality,
									"OSSZEFOGAS"
								) + 1
							);
						}}
					>
						-1
					</button>
					<button
						class="btn btn-primary btn-small btn-blue"
						@click=${() => {
							setMandates("OSSZEFOGAS", 133);
						}}
					>
						2/3
					</button>
					<button
						class="btn btn-primary btn-small btn-blue"
						@click=${() => {
							setMandates("OSSZEFOGAS", 100);
						}}
					>
						50%+1
					</button>
				</div>
				<div class="space-y-1">
					<button
						class="btn btn-primary btn-small"
						@click=${() => {
							setMandates("FIDESZ", 100);
						}}
					>
						50%+1
					</button>
					<button
						class="btn btn-primary btn-small"
						@click=${() => {
							setMandates("FIDESZ", 133);
						}}
					>
						2/3
					</button>
					<button
						class="btn btn-primary btn-small"
						@click=${() => {
							setMandates(
								"FIDESZ",
								getAllSeats(
									calculated.modositottMagnet.seats.all,
									calculated.modositottMagnet.seats.nationality,
									"FIDESZ"
								) + 1
							);
						}}
					>
						+1
					</button>
				</div>
			</div>
		</div>`;
	};

	return html`<div class="mb-4">
		<div class="grid grid-cols-1 gap-4">
			${htmlModositok()} ${htmlMandateSlider()}
			${htmlSzavazatok(calculated.currentMagnet, calculated.modositottMagnet)}
			${htmlOevks(currentSource, calculated.modositottSource)}
		</div>
	</div>`;
};

if (isBrowser() && customElements.get(name) === undefined) {
	customElements.define(
		name,
		component<HTMLElement & Properties>(Component, {
			useShadowDOM,
			observedAttributes,
		})
	);
}

// React Wrapper

const Chief = (props) => {
	const [ref] = useCustomElement(props);
	return (
		<div>
			<div className="mt-4">
				<th-chief ref={ref}></th-chief>
			</div>
		</div>
	);
};

export default Chief;
