/* eslint-disable no-null/no-null */
import { html, svg } from "lit-html";
import { useCallback, useEffect, useRef, useState, useWindowSize } from "../../util/CustomHauntedHooks";
import "./mandateSlider.scss";

interface MandateSliderProperties {
	fideszMandate: number;
}

interface Coords {
	x: number;
	y: number;
}

export const useMandateSlider = (props: MandateSliderProperties) => {
	// HELPER

	const calcMandate = (x: number, dragOffsetX: number) => {
		return x - dragOffsetX - DEFAULTS.sliderBarOffsetX + DEFAULTS.dragWidth / 2;
	};

	const trimMandate = (m: number) => {
		return m < 0 ? 0 : m > 199 ? 199 : m;
	};

	const endDrag = (coords: Coords) => {
		if (draggedElement) {
			setFideszMandate(trimMandate(calcMandate(coords.x, draggedElementOffset.x)));
			setDispatchMandate(true);
			setDraggedElement(undefined);
		}
	};

	const startDrag = (selectedElement: SVGRectElement, offset: Coords) => {
		if (selectedElement.attributes.getNamedItem("draggable")) {
			offset.x -= parseFloat(selectedElement.getAttributeNS(null, "x") as string);
			offset.y -= parseFloat(selectedElement.getAttributeNS(null, "y") as string);
			setDraggedElementOffset({ x: offset.x, y: offset.y });
			setDraggedElement(selectedElement);
		}
	};

	const drag = (coords: Coords) => {
		if (draggedElement) {
			const m = calcMandate(coords.x, draggedElementOffset.x);
			let x = coords.x - draggedElementOffset.x + DEFAULTS.dragWidth / 2;
			if (m < 0) {
				x = 0 + DEFAULTS.sliderBarOffsetX;
			} else if (m > 199) {
				x = DEFAULTS.viewBoxWidth - DEFAULTS.sliderBarOffsetX;
			}
			if (!isNaN(x)) {
				draggedElement.setAttributeNS(null, "x", (x - DEFAULTS.dragWidth / 2).toString());
				(svgSliderPeg.current as SVGRectElement).setAttributeNS(
					null,
					"x",
					(x - DEFAULTS.pegWidth / 2).toString()
				);
				(svgSliderText.current as SVGTextElement).setAttributeNS(null, "x", x.toString());
				const tm = Math.round(trimMandate(calcMandate(coords.x, draggedElementOffset.x)));
				(svgSliderText.current as SVGTextElement).textContent = tm.toString();
			}
		}
	};

	const getSVGMousePosition = (svgContainer: SVGSVGElement | undefined, e: MouseEvent) => {
		const CTM = svgContainer?.getScreenCTM() as DOMMatrix;
		return {
			x: (e.clientX - CTM.e) / CTM.a,
			y: (e.clientY - CTM.f) / CTM.d,
		};
	};

	const getSVGTouchPosition = (svgContainer: SVGSVGElement | undefined, e: TouchEvent) => {
		const CTM = svgContainer?.getScreenCTM() as DOMMatrix;
		return {
			x: (e.changedTouches[0].clientX - CTM.e) / CTM.a,
			y: (e.changedTouches[0].clientY - CTM.f) / CTM.d,
		};
	};

	// COMPONENT

	const svgRoot = useRef<SVGSVGElement | undefined>(undefined);
	const svgSliderText = useRef<SVGTextElement | undefined>(undefined);
	const svgSliderPeg = useRef<SVGRectElement | undefined>(undefined);
	const [draggedElement, setDraggedElement] = useState<SVGRectElement | undefined>(undefined);
	const [draggedElementOffset, setDraggedElementOffset] = useState<Coords>({ x: 0, y: 0 });
	const windowSize = useWindowSize();
	const [fideszMandate, setFideszMandate] = useState<number>(props.fideszMandate);
	const [dispatchMandate, setDispatchMandate] = useState<boolean>(false);

	const DEFAULTS = {
		sliderBarOffsetX: 2,
		sliderBarOffsetY: 12,
		sliderBarHeight: windowSize[0] > 768 ? 10 : 20,
		numberOffsetY: windowSize[0] > 768 ? 8 : 12,
		viewBoxWidth: 203,
		viewBoxHeight: windowSize[0] > 768 ? 38 : 48,
		dragWidth: 16,
		pegWidth: windowSize[0] > 768 ? 1 : 2,
	};

	useEffect(() => {
		svgRoot.current = document.getElementById("js_svg_root") as any;
		svgSliderText.current = document.getElementById("js_slider_text") as any;
		svgSliderPeg.current = document.getElementById("js_slider_peg") as any;
	}, []);

	useEffect(() => {
		if (Math.round(fideszMandate) !== props.fideszMandate) {
			setFideszMandate(props.fideszMandate);
		}
		(svgSliderText.current as SVGTextElement).textContent = Math.round(props.fideszMandate).toString();
	}, [props.fideszMandate]);

	const startMouseDrag = (e: DragEvent) => {
		startDrag(e.target as SVGRectElement, getSVGMousePosition(svgRoot.current, e));
	};

	const startTouchDrag = (e: TouchEvent) => {
		startDrag(e.target as SVGRectElement, getSVGTouchPosition(svgRoot.current, e));
	};

	const mouseDrag = useCallback(
		(e: MouseEvent) => {
			if (draggedElement) {
				e.preventDefault();
				drag(getSVGMousePosition(svgRoot.current, e));
			}
		},
		[draggedElement]
	);

	const touchDrag = useCallback(
		(e: TouchEvent) => {
			drag(getSVGTouchPosition(svgRoot.current, e));
		},
		[draggedElement]
	);

	const endMouseDrag = useCallback(
		(e: MouseEvent) => {
			endDrag(getSVGMousePosition(svgRoot.current, e));
		},
		[draggedElement]
	);

	const endTouchDrag = useCallback(
		(e: TouchEvent) => {
			endDrag(getSVGTouchPosition(svgRoot.current, e));
		},
		[draggedElement]
	);

	useEffect(() => {
		if (draggedElement) {
			document.addEventListener("mousemove", mouseDrag);
			document.addEventListener("touchmove", touchDrag);
			document.addEventListener("mouseup", endMouseDrag);
			document.addEventListener("touchend", endTouchDrag);
		} else {
			document.removeEventListener("mousemove", prevListeners.current.mouseDrag);
			document.removeEventListener("touchmove", prevListeners.current.touchDrag);
			document.removeEventListener("mouseup", prevListeners.current.endMouseDrag);
			document.removeEventListener("touchend", prevListeners.current.endTouchDrag);
		}

		return () => {
			document.removeEventListener("mousemove", prevListeners.current.mouseDrag);
			document.removeEventListener("touchmove", prevListeners.current.touchDrag);
			document.removeEventListener("mouseup", prevListeners.current.endMouseDrag);
			document.removeEventListener("touchend", prevListeners.current.endTouchDrag);
		};
	}, [draggedElement]);

	const prevListeners = useRef<{ mouseDrag?: any; touchDrag?: any; endMouseDrag?: any; endTouchDrag?: any }>({});
	useEffect(() => {
		prevListeners.current = {
			mouseDrag,
			touchDrag,
			endMouseDrag,
			endTouchDrag,
		};
	});

	useEffect(() => {
		if (dispatchMandate) {
			setDispatchMandate(false);
		}
	}, [dispatchMandate]);

	const htmlSlider = () => {
		return html`<div class="border-0">
			${svg`<svg id="js_svg_root" viewBox="0 0 ${DEFAULTS.viewBoxWidth} ${DEFAULTS.viewBoxHeight}">
                ${[...Array(10).keys()].map((i) => {
					return svg`<rect x="${i * 20 + DEFAULTS.sliderBarOffsetX}" y="${
						DEFAULTS.sliderBarOffsetY
					}" width="0.2" height="${DEFAULTS.sliderBarHeight + 4}" fill="#bababa"/><text x="${
						i * 20 + DEFAULTS.sliderBarOffsetX + 0.2
					}" y="${
						DEFAULTS.sliderBarOffsetY + DEFAULTS.sliderBarHeight + DEFAULTS.numberOffsetY
					}" text-anchor="middle">${i * 20}</text>`;
				})}
                <rect x="${DEFAULTS.sliderBarOffsetX}" y="${DEFAULTS.sliderBarOffsetY}" width="66" height="${
				DEFAULTS.sliderBarHeight
			}" fill="#1a237e" stroke="black" stroke-width="0"/>
                <rect x="${66 + DEFAULTS.sliderBarOffsetX}" y="${DEFAULTS.sliderBarOffsetY}" width="34" height="${
				DEFAULTS.sliderBarHeight
			}" fill="#2b38cc" stroke="black" stroke-width="0"/>
                <rect x="${100 + DEFAULTS.sliderBarOffsetX}" y="${DEFAULTS.sliderBarOffsetY}" width="33" height="${
				DEFAULTS.sliderBarHeight
			}" fill="#f0a160" stroke="black" stroke-width="0"/>
                <rect x="${133 + DEFAULTS.sliderBarOffsetX}" y="${DEFAULTS.sliderBarOffsetY}" width="66" height="${
				DEFAULTS.sliderBarHeight
			}" fill="#ef6c00" stroke="black" stroke-width="0"/>

                <text id="js_slider_text" x="${fideszMandate + DEFAULTS.sliderBarOffsetX}" y="${
				-DEFAULTS.numberOffsetY + 17
			}" text-anchor="middle">${Math.round(fideszMandate)}</text>

                <rect id="js_slider_peg" x="${fideszMandate - DEFAULTS.pegWidth / 2 + DEFAULTS.sliderBarOffsetX}" y="${
				DEFAULTS.sliderBarOffsetY - 2
			}" width="${DEFAULTS.pegWidth}" height="${DEFAULTS.sliderBarHeight + 4}" fill="black">
                </rect>

                <rect draggable="true" x="${fideszMandate - DEFAULTS.dragWidth / 2 + DEFAULTS.sliderBarOffsetX}" y="${
				DEFAULTS.sliderBarOffsetY - 2
			}" width="${DEFAULTS.dragWidth}" height="${
				DEFAULTS.sliderBarHeight + 4
			}" fill="black" fill-opacity="0%" @mousedown=${startMouseDrag} @touchstart=${startTouchDrag}>
                </rect>
            </svg>`}
		</div>`;
	};

	return { template: htmlSlider, fideszMandate: Math.round(fideszMandate), dispatchMandate };
};
